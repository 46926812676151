import { MutationCreateUserArgs, User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { randomCode, validMail } from "@/graphql/utils/send-mail";
import { useValidation } from "vue3-form-validation";
import { useRouter } from "vue-router";
import { USER_FIELDS } from "@/graphql/user/user.sdl";
import { CONSTANTS, trim } from "@/graphql/utils/utils";

type CreateUserData = {
  createUser: User;
};

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
        ${USER_FIELDS}
        responsible { id accepted }
    }
  }
`;

export const useCreateUser = () => {
  const { push, currentRoute } = useRouter();
  const instigatorId = Number(currentRoute.value.params["id"]) || null;
  const accountBaseUrl = localStorage.getItem(CONSTANTS.appSource);

  function validInstigator(n: number) {
    if (!instigatorId) {
      if (!n) return true;
      return n.toString().length === 6;
    }
    return true;
  }
  const { validateFields, hasError, form } = useValidation({
    language: 0,
    activation: randomCode(),
    settings: [2, 1, 0, 0, 1, 0],
    name: {
      $value: "",
      $rules: [(n: string) => !n && t("signUp.validation.name")],
    },
    email: {
      $value: "",
      $rules: [(m: string) => !validMail(m) && t("signUp.validation.email")],
    },
    instigatorId: {
      $value: instigatorId,
      $rules: [
        (n: number) => !validInstigator(n) && t("signUp.validation.codePromo"),
      ],
    },
    password: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
    confirm: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
  });

  const toast = useToast();
  const { t } = useI18n();

  const { onDone, mutate, loading, onError } = useMutation<
    CreateUserData,
    MutationCreateUserArgs
  >(CREATE_USER, { fetchPolicy: "no-cache" });
  onDone(({ data, errors }) => {
    if (data?.createUser) {
      if (!data.createUser.responsible.length) {
        toast.add({
          severity: "success",
          summary: t("sendMail.title"),
          detail: t("signUp.success"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
        sessionStorage.setItem(
          CONSTANTS.pendingAccount,
          JSON.stringify({
            id: data.createUser.id,
            name: data.createUser.name,
            email: data.createUser.email,
            resentTimes: data.createUser.resentTimes,
          })
        );
        setTimeout(() => {
          void push(`${accountBaseUrl}/verify-account`);
        }, 2000);
      } else
        toast.add({
          severity: "warn",
          summary: t("signUp.welcome"),
          detail: t(`signUp.exist`),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
    } else if (errors) {
      const message = errors[0].message === "instigatorNotFound";
      toast.add({
        severity: "warn",
        summary: t("signUp.registration"),
        detail: t(message ? "instigator.notFound" : "signUp.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("signUp.welcome"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submit() {
    validateFields().then((value) => {
      trim(value);
      const { confirm, ...input } = value;
      void mutate({ input });
    });
  }
  function onKeyEnter(field: string, value: any) {
    if (typeof value === "string") value = value.trim();
    form[field].$value = value;
    form[field].$validate();
    submit();
  }
  return {
    submit,
    loading,
    validateFields,
    hasError,
    form,
    onKeyEnter,
    instigatorId,
    getInstigator: (val: string) => Number(val) || null,
    accountBaseUrl,
  };
};
