
import { defineComponent } from "vue";
import { useCreateUser } from "@/graphql/user/create.user";
import FieldErrors from "../components/common/FieldErrors.vue";

export default defineComponent({
  name: "SignUp",
  components: {
    FieldErrors,
  },
  setup() {
    return {
      ...useCreateUser(),
    };
  },
});
